import React from 'react'
import { graphql} from 'gatsby'
import Layout from '../layouts'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import './newsMain.scss'
import MainNews from '../components/MainNews/MainNews'

const data = {
	elderly: {
		title: "Пожилые люди и старение",
		description: "Материалы, карточки и тесты, посвященные пожилым людям и старению. Медиарешебник – электронное пособие для журналистов.",
		slug: "elderly"
	},
	lgbtq: {
		title: "ЛГБТК+ сообщество",
		description: "Материалы, карточки и тесты, посвященные ЛГБТК+ сообществу. Медиарешебник – электронное пособие для журналистов.",
		slug: "lgbtq"
	},
	transgender: {
		title: "Трансгендерные люди",
		description: "Материалы, карточки и тесты, посвященные трансгендерным людям. Медиарешебник – электронное пособие для журналистов.",
		slug: "transgender"
	},
	roma: {
		title: "Народность Рома",
		description: "Материалы, карточки и тесты, посвященные народности Рома. Медиарешебник – электронное пособие для журналистов.",
		slug: "roma"
	},
	gender: {
		title: "Гендерная тематика",
		description: "Материалы, карточки и тесты, на гендерную тематику. Медиарешебник – электронное пособие для журналистов.",
		slug: "gender"
	}, 
	onko: {
		title: "Онкопациенты",
		description: "Материалы, карточки и тесты, посвященные онкопациеннтам. Медиарешебник – электронное пособие для журналистов.",
		slug: "onko"
	}, 
	disability: {
		title: "Люди с инвалидностью",
		description: "Материалы, карточки и тесты, посвященные людям с инвалидностью. Медиарешебник – электронное пособие для журналистов.",
		slug: "disability"
	}, 
	drugs: {
		title: "Люди, употребляющие наркотики",
		description: "Материалы, карточки и тесты, посвященные людям употребляющим наркотики. Медиарешебник – электронное пособие для журналистов.",
		slug: "drugs"
	}, 
	migrants: {
		title: "Мигранты",
		description: "Материалы, карточки и тесты, посвященные мигрантам. Медиарешебник – электронное пособие для журналистов.",
		slug: "migrants"
	}, 
	hiv: {
		title: "Люди, живущие с ВИЧ",
		description: "Материалы, карточки и тесты, посвященные людям живущим с ВИЧ. Медиарешебник – электронное пособие для журналистов.",
		slug: "hiv"
	} 
}

const mainMewsTamplate = props => {
	const allNews = props.data.news.allNewss.edges.filter(item => !item.node.show_in_rubric)
	const rubric = props.pageContext.rubric

	const meta = data[rubric]
	const metaImages = props.data.images.edges
	const metaImage = metaImages.filter(item => item.node.fluid.src.includes(rubric + '.jpg'))[0].node.fluid
	return (
		<Layout currentSlug={props.path}>
			<Seo
				title={meta.title}
				description={meta.description}
				slug={meta.slug + '/news/'}
				image={{file: {
					url: '//aboutpeople.info' + metaImage.src
				}}}
			/>
			
			<MainNews news={allNews} image={metaImage} title={meta.title} rubric={rubric} />
      <Footer />
    </Layout>
	)
}
export const pageQuery = graphql`
query lessonssQuery($id: String!, $rubric: String!) {
	lesson: contentfulLessons(id: { eq: $id }) {
		slug
		tests {
      slug
      libraryLink {
        slug
			}
			videoLibraryLink {
				slug
				mediaLibraryLink {
          slug
        }
      }
    }
		tag {
			id
			displayTitle
			title
		}
	}

	images: allImageSharp {
    edges {
      node {
        fluid {
					src
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
	news: prismic {
		allNewss(sortBy: meta_lastPublicationDate_DESC, where: {rubric: $rubric}) {
			edges {
				node {
					description
					meta_image
					meta_imageSharp {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					_meta {
            uid
          }
					title
					rubric
					show_in_rubric
				}
			}
		}
	}
}

`

export default mainMewsTamplate