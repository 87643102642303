import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import cn from 'classnames'

const MainNews = ({news, image, title, rubric}) => {
	const [isLoaded, setIsLoaded] = useState(false)
	useEffect(() => {
		setIsLoaded(true)
	}, [isLoaded])
	return (
	<div className={cn("main-news", {
		"main-news--loaded": isLoaded
	})}>
		
		<div className="news-cards">
			<div className="main-news-title">
				<h1 className="main-news-title__info">
					{title}
				</h1>
				<Img 
					className={`main-news-title__image`} 
					fluid={image} 
					alt={title}
				/>
			</div>		
			{news.map((item, index) => (
				<div key={index} style={{transitionDelay: `${(index ? 1500 : 1200 )+ 10 * index }ms`}} className={cn("news-card", {
					"news-card--main": index === 0,
				})}>
					<Link className="news-card__link" role="link" aria-label="link to main page" to={`/${rubric}/news/${item.node._meta.uid}`}>
						<Img 
								className={`news-card__image`} 
								fluid={item.node.meta_imageSharp.childImageSharp.fluid} 
								alt={item.node.title[0].text}
							/>
							<div className="news-card__info">
								<h2 className="news-card__title">{item.node.title[0].text}</h2>
								<p className="news-card__description">
									{item.node.description[0].text}
								</p>
							</div>
					</Link>
				</div>
			))}
		</div>
	</div>
	)
}

export default MainNews